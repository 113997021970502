import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { font, color, ease, device } from "../../layout/global"
import { CountryDropdown } from "react-country-region-selector"
import SubmitButton from "../SubmitButton"
import TermsCheckbox from "../TermsCheckbox"
import fbTrack from "../../hooks/fbTrack"

import { ConsentGtag } from "../../utils/consentGtag"

import { handleClick } from "../../utils/custom"

const axios = require("axios")

const FormContact = ({ id, info, lang, location, contentMsg }) => {
  const [country, setCountry] = useState("")
  const [option, setOption] = useState(2) // eslint-disable-line
  const [showLoader, setShowLoader] = useState(false)

  const [successful, setSuccessful] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [focused, setFocused] = useState(false)

  const validityString = useRef(null)
  const validityStringEmail = useRef(null)

  const onSuccess = () => {
    document.getElementById("contact-form").style.opacity = "0"
    setTimeout(function () {
      setSuccessful(true)
      setTimeout(function () {
        document.getElementById("successful").style.opacity = "1"
        setTimeout(function () {
          document.getElementById("successful").style.opacity = "0"
          setTimeout(function () {
            setSuccessful(false)
            setTimeout(function () {
              document.getElementById("contact-form").style.opacity = "1"
            }, 150)
          }, 300)
        }, 3500)
      }, 150)
    }, 300)
  }
  const onError = () => {
    document.getElementById("contact-form").style.opacity = "0"
    setTimeout(function () {
      setHasError(true)
      setTimeout(function () {
        document.getElementById("error").style.opacity = "1"
        setTimeout(function () {
          document.getElementById("error").style.opacity = "0"
          setTimeout(function () {
            setHasError(false)
            setTimeout(function () {
              document.getElementById("contact-form").style.opacity = "1"
            }, 150)
          }, 300)
        }, 3500)
      }, 150)
    }, 300)
  }
  const doFocus = () => {
    setFocused(true)
  }

  const selectCountry = val => {
    setCountry(val)
  }

  function showFileName() {
    let fileName = document.getElementById("file-upload")
    let inputName = document.getElementById("file-upload-readonly")

    if (fileName.files.item(0)) {
      inputName.value = fileName.files.item(0).name
      inputName.defaultValue = info.field10
    }
  }

  function doSubmit(e) {
    e.preventDefault()
    setShowLoader(true)
    ConsentGtag()
    var formData = new FormData()
    formData.append("name", document.querySelector("#fc-name").value)
    formData.append("email", document.querySelector("#fc-email").value)
    formData.append("tel", document.querySelector("#fc-tel").value)
    formData.append("country", country)
    formData.append("lang", lang)
    formData.append(
      "postalcode",
      document.querySelector("#fc-postal-code").value
    )
    formData.append("option", option)
    formData.append("serial-no", document.querySelector("#serial-no").value)
    formData.append("file", document.getElementById("file-upload").files[0])
    formData.append("message", document.querySelector("#fs2-message").value)
    document.querySelector("#terms-01").checked &&
      formData.append("terms01", document.querySelector("#terms-01").value)
    document.querySelector("#terms-02").checked &&
      formData.append("terms02", document.querySelector("#terms-02").value)
    formData.append("interest", "")

    axios
      .post("https://ramalhos.com/form-contact.php", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data; charset=UTF-8",
        },
      })
      .then((res) => {
        console.log(res)
        var aux = res.data.split("<br>")
        var len = aux.length
        if (aux[len - 1].includes("success")) {
          setShowLoader(false)
          onSuccess()
          if (typeof window !== "undefined") {
            window.gtag("event", "form_contacto", {
              form: "Formulário de Contacto",
              assunto: "Assistência Técnica",
              gama: ''
            })
            window.gtag('event', 'conversion', { 'send_to': 'AW-11288001937/03dSCOSm1OMYEJH7xIYq' });
          }
          fbTrack("trackCustom", "Formulário - Assistência 24H")

        } else {
          setShowLoader(false)
          onError()
        }
      })
  }

  const triggerRef = useRef(null),
    input1Ref = useRef(null),
    input2Ref = useRef(null),
    input3Ref = useRef(null),
    input4Ref = useRef(null),
    input5Ref = useRef(null),
    inputField1 = useRef(null),
    inputField2 = useRef(null),
    inputField3 = useRef(null),
    inputField4 = useRef(null),
    inputField5 = useRef(null)

  useEffect(() => {
    if (!showLoader) {
      if (document.getElementById("contact-form")) {
        document.getElementById("contact-form").style.opacity = "1"
      }
      switch (lang) {
        case "pt":
          validityString.current = `Campo obrigatório.`
          validityStringEmail.current = `O e-mail deve estar no seguinte formato: xxx@yyy.zzz`
          break
        case "en":
          validityString.current = `Mandatory field.`
          validityStringEmail.current = `The e-mail must be in the following format: xxx@yyy.zzz`
          break
        case "es":
          validityString.current = `Campo obligatorio.`
          validityStringEmail.current = `El correo electrónico debe tener el siguiente formato: xxx@yyy.zzz`
          break
        case "fr":
          validityString.current = `Champ obligatoire.`
          validityStringEmail.current = `L'e-mail doit être au format suivant : xxx@yyy.zzz`
          break
        default:
          validityString.current = `Campo obrigatório.`
          validityStringEmail.current = `O e-mail deve estar no seguinte formato: xxx@yyy.zzz`
          break
      }
      inputField1.current.setCustomValidity(validityString.current)
      inputField2.current.setCustomValidity(validityString.current)
      inputField3.current.setCustomValidity(validityString.current)
      inputField5.current.setCustomValidity(validityString.current)
    }
  }, [lang])

  useEffect(() => {
    if (!showLoader) {
      if (focused) {
        !inputField1.current.validity.valueMissing
          ? inputField1.current.setCustomValidity("")
          : inputField1.current.setCustomValidity(validityString.current)

        if (inputField2.current.validity.typeMismatch) {
          inputField2.current.setCustomValidity(validityStringEmail.current)
        } else if (inputField2.current.validity.valueMissing) {
          inputField2.current.setCustomValidity(validityString.current)
        } else {
          inputField2.current.setCustomValidity("")
        }

        !inputField3.current.validity.valueMissing
          ? inputField3.current.setCustomValidity("")
          : inputField3.current.setCustomValidity(validityString.current)
        !inputField5.current.validity.valueMissing
          ? inputField5.current.setCustomValidity("")
          : inputField5.current.setCustomValidity(validityString.current)
        setFocused(false)
      }
    }
  }, [focused])

  return (
    <Wrapper id={id} ref={triggerRef}>

      <div className="form-container">
        <div className="title">
          <h2>{info.assistance}</h2>
        </div>
        {
          showLoader ?
            <LoaderStyle>
              <div className="spinner-container">
                <div className="loading-spinner">
                </div>
              </div>
            </LoaderStyle>
            :
            hasError ? (
              <div id="error">
                <h3>{info.error1}</h3>
                <p>{info.error2}</p>
              </div>
            ) : !successful ? (
              <form
                id="contact-form"
                onSubmit={doSubmit}
                method="POST"
                autoComplete="off"
              >
                <div className="input-wrapper" id="fcw-name" ref={input1Ref}>
                  <input
                    ref={inputField1}
                    onChange={doFocus}
                    className="fc-input invalid"
                    id="fc-name"
                    name="name"
                    type="text"
                    placeholder={info.field1}
                    required
                  ></input>
                  <label htmlFor="fc-name" className="label">
                    {info.field1} <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="input-wrapper" id="fcw-email" ref={input2Ref}>
                  <input
                    ref={inputField2}
                    onChange={doFocus}
                    className="fc-input"
                    id="fc-email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    required
                  ></input>
                  <label htmlFor="fc-email" className="label">
                    {info.field2} <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="input-wrapper" id="fcw-tel" ref={input3Ref}>
                  <input
                    ref={inputField3}
                    onChange={doFocus}
                    className="fc-input"
                    id="fc-tel"
                    name="tel"
                    type="text"
                    placeholder="Tel"
                    pattern="[0-9]*"
                    required
                  ></input>
                  <label htmlFor="fc-tel" className="label">
                    {info.field3} <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="input-wrapper" id="fcw-country" ref={input4Ref}>
                  <CountryDropdown
                    id="fc-country"
                    name="country"
                    defaultOptionLabel=""
                    required
                    value={country}
                    onChange={val => selectCountry(val)}
                    ref={inputField4}
                  />
                  <label htmlFor="fc-country" className="label">
                    {info.field4} <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="input-wrapper" id="fcw-postal-code" ref={input5Ref}>
                  <input
                    ref={inputField5}
                    onChange={doFocus}
                    className="fc-input"
                    id="fc-postal-code"
                    name="postal-code"
                    type="text"
                    placeholder="Código Postal"
                    required
                  ></input>
                  <label htmlFor="fc-postal-code" className="label">
                    {info.field5} <span style={{ color: "red" }}>*</span>
                  </label>
                </div>

                <div id="fcw-secondary">
                  <div className="form-secondary">
                    <div className="input-wrapper">
                      <input
                        id="serial-no"
                        type="text"
                        className="fs-input"
                        placeholder="Tipo de Equipamento"
                      />
                      <label htmlFor="serial-no" className="fs-label">
                        {info.field9}
                      </label>
                    </div>
                    <div className="input-wrapper file-upload">
                      <input
                        type="text"
                        id="file-upload-readonly"
                        placeholder={info.field10}
                        readOnly="readonly"
                      />
                      <input id="file-upload" type="file" onChange={showFileName} />
                      <label id="file-upload-label" htmlFor="file-upload">
                        {info.field10}
                      </label>
                      <input type="hidden" name="MAX_FILE_SIZE" value="10000000" />
                    </div>
                    <div className="input-wrapper">
                      <textarea
                        className="fs-message"
                        id="fs2-message"
                        placeholder={info.field6}
                      ></textarea>
                      <label
                        htmlFor="fs2-message"
                        className="category-label message-label2"
                      >
                        {info.field6}
                      </label>
                      <TermsCheckbox
                        hasTerms
                        hasShare
                        text1={info.button1}
                        text2={info.button2}
                        text3={info.button3}
                        language={lang}
                      />
                    </div>
                    <div className="submit-wrapper">
                      <SubmitButton text={info.submit} />
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <div id="successful">
                <span></span>
                <h3>{info.confirmation1}</h3>
                <p>{info.confirmation2}</p>
              </div>
            )}
      </div>

      <div className="callnow-container">
        <p id="callnow-string">{info.call}</p>
        {lang === 'mx' ?
          <>
            <a
              onClick={() => handleClick("tel:+5588523123", lang)}
              alt="Call Now" rel="noreferrer noopener">
              <p id="callnow-number">55 8852 3123</p>
            </a>
            <a onClick={() => handleClick("tel:+5522537326", lang)}
              alt="Call Now" rel="noreferrer noopener">
              <p id="callnow-number">55 2253 7326</p>
            </a>
          </>
          :
          <a onClick={() => handleClick("tel:+351234630200", lang)}
            alt="Call Now" rel="noreferrer noopener">
            <p id="callnow-number">+351 234 630 200
              <br /><span style={{ fontSize: "1.5rem", fontStyle: "italic" }}>{contentMsg.fixo}</span>
            </p>
          </a>
        }

      </div>
    </Wrapper>
  )
}

export default FormContact


const LoaderStyle = styled.div`
min-height: 40vh;
display: grid;
align-items: center;

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner-container{
  justify-content: center;
  display: grid;
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid rgba(225,6,0,1); /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}`

const Wrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  background-color: ${color.red};
  padding-top: 125px;

  .form-container {
    position: relative;
    margin: 10% auto 0 auto;
    box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.35);
    z-index: 1;

    @media ${device.mobileP}{
      width:100%;
    }

    @media ${device.tabletL}{
      width: 55%;
    }
  }

  .title {
    /* background-color: ${color.whiteMostOpacity}; */
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title h2 {
    ${font.robotoBold};
    font-size: 1.5rem;
    color: ${color.white};
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }

  #successful {
    opacity: 0;
    transition: all 0.3s ${ease.out};
    text-align: center;
    display: grid;
    grid-template-rows: auto auto auto;
    justify-content: center;
    min-height:50vh;
    span {
      display: block;
      width: 7px;
      height: 12px;
      border: solid ${color.red};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      justify-self: center;
      margin-bottom: 25px;
    }
    h3 {
      ${font.financierBoldN};
      font-size: 1.4rem;
      letter-spacing: -0.1px;
      color: ${color.greyDark};
    }
    p {
      ${font.robotoMedium};
      text-transform: uppercase;
      color: ${color.grey};
    }
  }

  #error {
    opacity: 0;
    transition: all 0.3s ${ease.out};
    text-align: center;
    display: grid;
    grid-template-rows: auto auto auto;
    justify-content: center;
    h3 {
      ${font.financierBoldN};
      font-size: 1.4rem;
      letter-spacing: -0.1px;
      color: ${color.greyDark};
    }
    p {
      ${font.robotoMedium};
      text-transform: uppercase;
      color: ${color.grey};
    }
  }

  .container {
    @media ${device.tabletP}{
      width: 90%;
      max-width: 900px;
    }
  }
  
  form {
    display: grid;
    width: 100%;
    opacity: 0;
    transition: all 0.3s ${ease.out};
    background-color: ${color.white};
    padding: 5% 2.5%;

    @media ${device.mobileP}{
      grid-template-areas:
        'name'
        'email'
        'tel'
        'country'
        'postalcode'
        'select'
        'secondary';
    }

      @media ${device.tabletP}{
        grid-template-areas:
          'name name name'
          'email email tel'
          'country country postalcode'
          '. select .'
          'secondary secondary secondary';
      }
  }

  #fcw-name { grid-area: name }
  #fcw-email { grid-area: email }
  #fcw-tel { grid-area: tel }
  #fcw-country { grid-area: country }
  #fcw-postal-code { grid-area: postalcode }
  #fcw-secondary { grid-area: secondary }

  .input-wrapper {
    position: relative;
    margin: 10px;
  }

  .fc-input, #fc-country {
    width: 100%;
    border: 0;
    outline: 0;
    min-height: 44px;
    background-color: ${color.greyOpacity};
    color: ${color.greyDark};
    ${font.robotoMedium};
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    padding: 20px 12px 6px;
    box-shadow: none;
    
    &::placeholder {
      color: transparent;
      user-select: none;
    }
  }
  .fc-input {
    &:focus,
    &:not(:placeholder-shown) {
      & + .label {
        transform: translateY(-3px) scale(.75);
        opacity: 0.75;
      }
    }
  }
  #fc-country:valid ~ .label {
    transform: translateY(-3px) scale(.75);
    opacity: 0.75;
  }

  .label {
    position: absolute;
    left: 12px;
    top: 6px;
    color: ${color.greyDark};
    ${font.robotoMedium};
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    transform-origin: left;
    transform: translateY(8px) scale(1);
    pointer-events: none;
    transition: all 0.15s ${ease.out};
  }

  .select-wrapper {
    margin-top: 50px;
    display: grid;
    grid-area: select;
    grid-template-rows: repeat(2, auto);
  }

  .select-wrapper h2 {
    text-align: center;
    ${font.financierRegularM};
    
    font-size: 2.4rem;
    color: ${color.greyDark};
  }

  .select-wrapper select {
    border: 0;
    outline: 0;
    height: 44px;
    border-bottom: 1px solid ${color.greyDarker};
    ${font.robotoMedium};
    font-size: 1.4rem;
    color: ${color.grey};
  }

  .select-wrapper option {
    margin: 5px 0;
  }

  #fcw-secondary {
    @media ${device.mobileP}{
      margin:60px 0;
    }

    @media ${device.tabletP}{
      margin: 50px 25px 0;
    }
  }

  .form-secondary h3 {
    ${font.robotoMedium};
    text-transform: uppercase;
    letter-spacing: 1px;

    @media ${device.mobileP}{
      margin:0 10px;
    }

    @media ${device.tabletP}{
      margin:0;
    }
  }

  .category-picker {
    display: grid;
    
    @media ${device.mobileP}{
      grid-template-columns: repeat(2,1fr);
    }

    @media ${device.tabletP}{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      margin: 10px 40px;
    }
  }

  .category-picker-item {
    position: relative;
    margin: 5px;
  }

  .category-checkbox {
    opacity: 0;
    background: transparent;
    outline: 0;
    border: none;
    width: 0;
    height: 0;

    &::placeholder {
      color: transparent;
      user-select: none;
    }
  }

  .category-checkbox {
    &:focus,
    &:not(:placeholder-shown) {
      & + .category-label {
        transform: translateY(0) scale(.75);
        opacity: 0.75;
      }
    }
  }

  .category-label {
    ${font.robotoMedium};
    text-transform: uppercase;
    letter-spacing: 1px;
    color: ${color.grey};
    font-size: 1.4rem;
    margin-left: 16px;
    transition: all 0.15s ${ease.out};
    user-select: none;

    @media ${device.tabletP}{
      position: absolute;
    }
  
    &:hover {
      cursor:pointer;
    }
  }

  .fs-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    pointer-events: none;
    transition: all 0.15s ${ease.out};
  }

  .fs-checkmark:after {
    position: absolute;
    display: block;
    content: "";
    left: 4px;
    top: 2px;
    width: 7px;
    height: 12px;
    border: solid ${color.grey};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: all 0.15s ${ease.out};
  }

  .category-checkbox:checked ~ .fs-checkmark:after {
    border-color: ${color.red};
  }

  .category-checkbox:checked ~ .category-label {
    color: ${color.red};
  }

  .category-checkbox:hover:not(:checked) ~ .fs-checkmark:after {
    border-color: ${color.greyDarker};
  }

  .category-checkbox:hover:not(:checked) ~ .category-label {
    color: ${color.greyDarker};
  }

  .input-wrapper-message {
    position: relative;

    @media ${device.mobileP}{
      margin: 25px 10px;
    }

    @media ${device.tabletP}{
      margin: 25px 0 0;
    }
  }

  .fs-message {
    border: 0;
    border-bottom: 1px solid ${color.greyDark};
    padding: 15px 0px;
    outline: 0;
    width: 100%;
    position: relative;
    ${font.robotoMedium};
    letter-spacing: normal;
    color: ${color.greyDark};
    font-size: 1.4rem;
    min-height: 6rem;
    resize: vertical;

    &::placeholder {
      color: transparent;
      user-select: none;
    }
  }

  .fs-message ~ .category-label {
    left: 0;
    margin-left: 0;
    transform: translateY(1.5rem);
    transform-origin: left;
    color: ${color.greyDark};
  }

  .fs-message {
    &:focus,
    &:not(:placeholder-shown) {
      & + .category-label {
        transform: translateY(-.5rem) scale(.75);
        margin-left: 1px;
        opacity: 0.75;
      }
    }
  }

  .fs-input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid ${color.greyDark};
    outline: 0;
    min-height: 44px;
    color: ${color.greyDark};
    ${font.robotoMedium};
    font-size: 1.4rem;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    padding: 20px 12px 6px;
    margin-bottom: 25px;

    &::placeholder {
      color: transparent;
      user-select: none;
    }
  }

  .fs-input {
    &:focus,
    &:not(:placeholder-shown) {
      & + .fs-label {
        transform: translateY(-3px) scale(.75);
        opacity: 0.75;
      }
    }
  }

  .fs-label {
    position: absolute;
    left: 12px;
    top: 6px;
    color: ${color.greyDark};
    ${font.robotoMedium};
    font-size: 14px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    transform-origin: left;
    transform: translateY(8px) scale(1);
    pointer-events: none;
    transition: all 0.15s ${ease.out};
  }

  .submit-wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
  
  .file-upload {
    margin-bottom: 25px;

    @media ${device.tabletP}{
      display: grid;
      align-items: end;
      grid-template-columns: 40% 30%;
      grid-column-gap: 25px;
    }
  }

  #file-upload {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  #file-upload-readonly {
    border: 0;
    border-bottom: 1px solid ${color.greyDark};
    padding: 15px 0px 15px 12px;
    outline: 0;
    ${font.robotoMedium};
    letter-spacing: 0.8px;
    color: ${color.greyDark};
    font-size: 1.4rem;
    text-transform: uppercase;
    width: 100%;
    user-select: none;
    cursor: default;

    &::placeholder {
      padding: 6px 0px 0px 0px;
      color: ${color.greyDark};
      font-size: 1.4rem;
    }
  }

  #file-upload-label {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    outline: 0;
    min-height: 44px;
    height: 44px;
    width: auto;
    background-color: ${color.greyOpacity};
    color: ${color.greyDark};
    ${font.robotoMedium};
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    cursor: pointer;
  }

  #fs2-message, #fs3-message {
    padding-left: 12px;
    padding-right: 12px;
  }

  #fs2-message + .category-label, #fs3-message + .category-label {
    left: 12px;
  }

  #secondary-choice{
    @media ${device.mobileP}{
      margin:0 10px;
    }

    @media ${device.tabletP}{
      margin:0;
    }
  }

  .message-label, .message-label2, .message-label3{
    @media ${device.mobileP}{
      position:absolute;
    }
  }

  .callnow-container {
    width: 100%;
    background-color: ${color.white};

    @media ${device.mobileP}{
      padding:50px 0;
    }

    @media ${device.tabletL}{
      margin-top: -20%;
      padding: 25% 0 5% 0;
    }
  }
  .callnow-container p {
    display: block;
    text-align: center;
    margin: 0 auto;
  }
  #callnow-string {
    ${font.financierBold};
    letter-spacing: -0.6px;
    font-size: 1.6rem;
    color: ${color.greyDark};
  }
  #callnow-number {
    ${font.financierRegular};
    letter-spacing: -0ch.3px;
    font-size: 1.9rem;
    color: ${color.greyDark};

    &:hover{
      text-decoration:underline;
    }
  }
`
