import React from "react"
import Layout from "../layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import FormAssistance from "../components/FormContact/assistance"
import Hero from "../components/Hero"
import Introduction from "../components/Introduction"
import FeatureLine from "../components/FeatureLine"
import FeatureLineMobile from "../components/FeatureLine/mobile"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import { useTranslation, I18nextContext } from "gatsby-plugin-react-i18next"

const Assistencia = ({ location, data }) => {
  const { t } = useTranslation()
  const breakpoints = useBreakpoint()
  const activeLanguage = React.useContext(I18nextContext).language

  return (
    <Layout location={location} footer={t("footer", { returnObjects: true })} header={t("header", { returnObjects: true })}>
      <SEO
        pathname={location.pathname}
        title={t("seo", { returnObjects: true })["title"]}
        img={t("seo", { returnObjects: true })["image"]}
        imageHeight="628"
        imageWidth="1200"
        lang={activeLanguage}
        domain={location?.hostname}
      />

      {!(React.useContext(I18nextContext).language === "br") ? ( // SE NÃO FOR BRASILEIRO
        <FormAssistance id="form-contact" info={t("form", { returnObjects: true })} location={location} lang={activeLanguage} 
        contentMsg={t('header', { returnObjects: true })['msgMarkPhone']} />
      ) : (
        // SE FOR BRASILEIRO
        <>
          <Hero
            text={{
              title: t("assistance", { returnObjects: true })["title"],
              subtitle: t("assistance", { returnObjects: true })["subtitle"],
            }}
            image={t("assistance", { returnObjects: true })["image"]}
            color="white"
            hasFilter
            animateIn
            animateSemiOut
            br={t("assistance", { returnObjects: true })["imageNau"]}
            brLink={"https://ramalhosbrasil.auvo.com.br/"}
          />
          <Introduction
            className="scrollable"
            id="introduction"
            translation={t("assistance", { returnObjects: true })["introduction"]}
            br
            link={`https://ramalhosbrasil.auvo.com.br/`}
            btnString={t("assistance", { returnObjects: true })["introduction"]["button"]}
          />

          {!breakpoints.tl && (
            <>
              <FeatureLine
                id="first-line-aftersales"
                titleWidth="800px"
                textWidth="550px"
                _title={t("assistance", { returnObjects: true })["feature01_title"]}
                text={t("assistance", { returnObjects: true })["feature01_text"]}
              />
              <Introduction
                className="scrollable"
                id="form-contact"
                translation={t("assistance", { returnObjects: true })["introduction2"]}
                br
                link={`http://massamadreblog.rds.land/manutencao-preventiva`}
                btnString={t("assistance", { returnObjects: true })["introduction2"]["button"]}
              />
            </>
          )}
          {breakpoints.tl && (
            <>
              <FeatureLineMobile
                id="first-line-aftersales"
                _title={t("assistance", { returnObjects: true })["feature01_title"]}
                text1={t("assistance", { returnObjects: true })["feature01_textM"]}
                positionCenter
              />
              <Introduction
                className="scrollable"
                id="form-contact"
                translation={t("assistance", { returnObjects: true })["introduction2"]}
                br
                link={`http://massamadreblog.rds.land/manutencao-preventiva`}
                btnString={t("assistance", { returnObjects: true })["introduction2"]["button"]}
              />
            </>
          )}
        </>
      )}
    </Layout>
  )
}

export default Assistencia

export const QueryAssistancePT = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["assistance", "form", "footer", "header"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
